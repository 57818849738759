var __jsx = React.createElement;
import { VStack } from '@chakra-ui/react';
import { Box, Button, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@playful/design_system';
import { useQueryStringActions } from '@playful/utils';
import { useHoverDomains } from '../../workbench/useHoverDomains';
import React from 'react';
import useDisclosure from '../../utils/useDisclosure';
import PresentImage from './welcome-present.svg';
export var WelcomeFromDomainCTAModal = function WelcomeFromDomainCTAModal() {
  var _useDisclosure = useDisclosure(),
    isOpen = _useDisclosure.isOpen,
    closeDialog = _useDisclosure.onClose,
    onOpen = _useDisclosure.onOpen;
  var _useQueryStringAction = useQueryStringActions({
      startAction: onOpen,
      endAction: closeDialog,
      actionName: 'subscribe-success-domain-cta'
    }),
    onClose = _useQueryStringAction.handleEndAction;
  var _useHoverDomains = useHoverDomains({}),
    redirectToHover = _useHoverDomains.redirectToHover;
  return __jsx(Modal, {
    size: "xs",
    isOpen: isOpen,
    onClose: onClose,
    isCentered: true
  }, __jsx(ModalOverlay, null), __jsx(ModalContent, null, __jsx(Box, {
    position: "relative",
    h: "220px"
  }, __jsx(Box, {
    position: "absolute",
    top: "-95px",
    left: "-34px"
  }, __jsx(PresentImage, null))), __jsx(ModalHeader, {
    textAlign: "center"
  }, __jsx(Heading, {
    fontSize: "2xl",
    fontWeight: "bold"
  }, "Welcome to Hatch Pro!")), __jsx(ModalBody, null, __jsx(Text, {
    textAlign: "center",
    fontSize: "sm"
  }, "More projects, more pages, and more customization are yours! We can\u2019t wait to see what you create.")), __jsx(ModalFooter, null, __jsx(VStack, {
    w: "100%"
  }, __jsx(Button, {
    colorScheme: "yellow",
    variant: "solid",
    w: "100%",
    onClick: redirectToHover
  }, "claim your free domain"), __jsx(Button, {
    variant: "unstyled",
    onClick: onClose
  }, __jsx(Text, {
    as: "span",
    fontSize: "sm",
    color: "blue.600"
  }, "I\u2019m not ready yet"))))));
};