import { extendDeep } from '@playful/utils';
import { isPage } from './runtime.js';
import { isReactor, ID, parseComponentType, } from './reactor.js';
import { appendPrototype, defaults, META, updatePrototypeChain } from './reactorObject.js';
import { isContainer } from './container.js';
// Use Symbols to hide private Component properties we don't want to confuse anyone with.
export const DESCRIPTION = Symbol('DESCRIPTION');
export const MASTER = Symbol('MASTER');
export const INITIAL_STATE = Symbol('INITIAL_STATE');
export const PRIMARY = Symbol('PRIMARY');
export const IS_PRIMARY_BREAKPOINT = Symbol('IS_PRIMARY_BREAKPOINT');
export const ACTIVE_BREAKPOINT = Symbol('ACTIVE_BREAKPOINT');
export const ACTIVE_BREAKPOINT_ID = Symbol('ACTIVE_BREAKPOINT_ID');
// Symbolic Object ids.
export const selfObjectId = -1;
export const parentObjectId = -2;
export const pageObjectId = -3;
// TODO: merge with ComponentProperties and Component
export const ComponentBase = {
    iam: 'ComponentBase',
    [ID]: 0,
    [META]: getDefaultMetaData(),
    [PRIMARY]: undefined,
    componentType: undefined,
    init() {
        super.init?.();
        console.assert(this.style === undefined, 'style should be undefined in init()', this.style);
        this.style = this?.project?.createReactor({});
    },
    dispose() {
        for (const key in this.variants) {
            if (isReactor(this.variants[key])) {
                this.variants[key].dispose();
            }
        }
        for (const key in this[PRIMARY]) {
            const value = this[PRIMARY][key];
            if (isReactor(value)) {
                value.dispose();
            }
        }
        this[PRIMARY]?.dispose();
        super.dispose?.();
    },
    getStoredState() {
        const ret = { [ID]: this[ID] };
        const keys = Object.keys(this[PRIMARY] || {});
        for (const k in keys) {
            const property = keys[k];
            let value = this[PRIMARY][property];
            if (value && isComponent(value)) {
                value = value.getStoredState();
            }
            else if (Array.isArray(value)) {
                value = value.map((v) => (isComponent(v) ? v.getStoredState() : v));
            }
            else if (property === 'Components' && isReactor(value)) {
                // The Components reactor is a Record<componentName, component> of Component masters/custom components.
                // Either imported or defined locally.
                const compNames = Object.keys(value);
                const compStates = {};
                for (const compName of compNames) {
                    const comp = value[compName];
                    compStates[compName] = comp.getStoredState();
                }
                value = compStates;
            }
            else if (property === 'variants') {
                // handle getting stored state for variants below, skip it here.
                continue;
            }
            else if (isReactor(value)) {
                console.error('Unexpected reactor in stored state', property, value, this, this[PRIMARY]);
                value = {};
            }
            ret[property] = value;
        }
        ret.variants = {};
        for (const variantKey in this[PRIMARY]?.variants) {
            ret.variants[variantKey] = this[PRIMARY]?.variants[variantKey].getState();
        }
        return ret;
    },
    getCurrentState() {
        const ret = { [ID]: this[ID] };
        for (const key in this) {
            if (this[META]?.properties[key] === undefined) {
                continue;
            }
            let value = this[key];
            if (isComponent(value)) {
                value = value.getCurrentState();
            }
            else if (Array.isArray(value)) {
                value = value.map((v) => (isComponent(v) ? v.getCurrentState() : v));
            }
            else if (isReactor(value)) {
                value = value.getState();
            }
            ret[key] = value;
        }
        ret.componentType = this.componentType;
        return ret;
    },
    switchBreakpoint(breakpointId) {
        if (breakpointId === this[ACTIVE_BREAKPOINT_ID]) {
            return;
        }
        if (breakpointId && this.variants?.[breakpointId]) {
            this[ACTIVE_BREAKPOINT] = this.variants[breakpointId];
            this[ACTIVE_BREAKPOINT_ID] = breakpointId;
        }
        else {
            delete this[ACTIVE_BREAKPOINT];
            delete this[ACTIVE_BREAKPOINT_ID];
        }
        initializeListeners(this);
        inheritProperties(this);
        if (this.children) {
            for (const child of this.children) {
                if (isComponent(child)) {
                    child.switchBreakpoint(breakpointId);
                }
            }
        }
        // TODO: effects
    },
};
export function isComponent(obj) {
    return typeof obj === 'object' && DESCRIPTION in obj;
}
export function getDefaultMetaData() {
    return {
        exported: false,
        commands: {},
        events: {},
        properties: {},
    };
}
// Attach listeners to detect property changes
function attachPropertyListeners(target, source) {
    const ret = [];
    if (isReactor(source)) {
        ret.push(
        // Handle changes to the source component
        source.onPropertyChange((reactor, property, newValue, oldValue, type) => {
            // TODO: cleanup typing
            inheritProperty(target, property);
            if (property === '_meta') {
                // Defer promoteToComponent to the next tick to avoid recursing since property changes are emitted immediately.
                setTimeout(() => promoteToComponent(target, target.project));
            }
        }));
    }
    ret.push(
    // Handle cases where the target component has a prop set to undefined
    target.onPropertyChange((reactor, property, newValue, oldValue, type) => {
        // Only trigger when a prop is being cleared
        if (newValue === undefined) {
            inheritProperty(target, property);
        }
    }));
    return ret;
}
export function initComponent(reactor, project) {
    // Hook Component up with its ComponentDescription.
    promoteToComponent(reactor, project);
    reactor[updatePrototypeChain]();
    // Now that all initial values, methods, inheritance, etc are set let the Component
    // further initialize itself (unless Project loading is going to do it).
    reactor.init?.();
    reactor.invalidate?.();
}
export function promoteToComponent(reactor, project) {
    const componentType = reactor.componentType;
    const component = reactor;
    // When processing _meta, componentType triggers calls to promoteToComponent
    // Remove componentType from _meta.properties
    if (typeof componentType !== 'string') {
        return;
    }
    //console.log(`promoteToComponent: ${reactor.name} ${componentType}`);
    //console.log('  component:', component);
    let master = resolveComponentType(componentType, project);
    if (!master) {
        console.warn(`No master for componentType ${componentType} (substituting Play Kit/Orphan)`);
        master = resolveComponentType('Play Kit/Orphan', project);
    }
    component[MASTER] = master;
    //console.log('  master:', master);
    // Attach the ComponentDescription to the Reactor -- now it is a Component!
    const description = getComponentDescription(master, project);
    if (!description) {
        console.warn(`No ComponentDescription for componentType ${componentType}`);
        return;
    }
    component[DESCRIPTION] = description;
    //console.log('  description:', description);
    // Build up META
    const meta = getDefaultMetaData();
    extendDeep(meta, component[PRIMARY]['_meta'], master._meta, description._meta);
    // Hide private props for instances of custom components
    if ('componentType' in master) {
        for (const name in meta.properties) {
            const prop = meta.properties[name];
            if (prop.private) {
                prop.hidden = true;
            }
        }
    }
    // Update META
    component[META] = meta;
    setupComponentDefaults(component, master);
    // make sure any property marked as isReactor is a reactor
    // This is where .children, effects, and pages are initialized as ReactorArrays
    Object.entries(meta.properties).forEach(([property, description]) => {
        const value = component[PRIMARY]?.[property] || description.default;
        if (description.isReactor && !isReactor(value)) {
            component[PRIMARY][property] = project.createReactor(value);
        }
    });
    //console.log('  META: ', component[META]);
    initializeListeners(component);
    inheritProperties(component);
}
function initializeListeners(component) {
    const master = component[MASTER];
    if (component._metaListeners) {
        component._metaListeners.forEach((o) => o.dispose());
    }
    // Attach listeners to detect property changes
    // This is where we start listening for changes to [stored] to sync them to runtime
    component._metaListeners = [
        ...attachPropertyListeners(component, component[PRIMARY]),
        ...(!!component[ACTIVE_BREAKPOINT]
            ? attachPropertyListeners(component, component[ACTIVE_BREAKPOINT])
            : []),
    ];
    if (isComponent(master)) {
        component._metaListeners.push(...attachPropertyListeners(component, master[PRIMARY]));
    }
}
// Return a ComponentDescription for a given component
function getComponentDescription(component, project) {
    while (isReactor(component)) {
        if (isComponent(component)) {
            // the component is already a Component, so just use its ComponentDescription
            return component[DESCRIPTION];
        }
        else {
            // Walk up the inheritance chain
            component = resolveComponentType(component.componentType, project);
            if (!component) {
                return undefined;
            }
        }
    }
    // the component is already JS ComponentDescription
    return component;
}
//TODO remove this - used by the sandbox api
export function getComponentProperties(component) {
    const props = {
        id: component[ID],
    };
    const propertyDescriptions = component[META].properties;
    if (!propertyDescriptions) {
        return props;
    }
    for (const key in propertyDescriptions) {
        const description = propertyDescriptions[key];
        if (description === undefined) {
            continue;
        }
        const value = component[key] ?? description.default;
        const { type: propType } = description;
        if (typeof value === 'function') {
            continue;
        }
        else if (propType === 'object') {
            if (isComponent(value)) {
                props[key] = getComponentProperties(value);
            }
            else {
                props[key] = convertToPlainObject(value);
            }
        }
        else if (propType === 'array') {
            props[key] = [];
            for (const el of value) {
                if (isComponent(el)) {
                    props[key].push(getComponentProperties(el));
                }
                else {
                    props[key].push(convertToPlainObject(el));
                }
            }
        }
        else {
            props[key] = value;
        }
    }
    return props;
    function convertToPlainObject(ob) {
        const props = {};
        for (const key in ob) {
            const value = ob[key];
            if (typeof value !== 'function') {
                props[key] = value;
            }
        }
        return props;
    }
}
function setupComponentDefaults(component, master) {
    const defProps = {};
    const meta = component[META];
    for (const property in meta.properties) {
        const defaultValue = meta.properties[property].default;
        if (master.hasOwnProperty(property)) {
            // Only inherit if the property is actually defined on the master
            // TODO: For Play Kit components this results in properties being pulled from the base component's
            // description (i.e. "name"). I don't think this is intentional or desirable.
            defProps[property] = master[property];
        }
        else if (defaultValue !== undefined) {
            // Only inherit if the default value is set
            defProps[property] = defaultValue;
        }
    }
    // Save the default properties as an object that will be added to the component's prototype chain
    // (by ReactorObject.updatePrototypeChain). We can then use OwnProperties to tell when a property
    // has been overridden from its default, even if by the same value.
    component[defaults] = defProps;
}
function getComponentSources(component) {
    const sources = [];
    // hierarchy = [activeBreakpoint, stored, master]
    if (component[ACTIVE_BREAKPOINT]) {
        sources.push(component[ACTIVE_BREAKPOINT]);
    }
    sources.push(component[PRIMARY]);
    if (isComponent(component[MASTER])) {
        sources.push(component[MASTER]);
    }
    return sources;
}
function getInheritedPropertyValue(component, property) {
    let value = component?.[defaults]?.[property];
    const sources = getComponentSources(component);
    for (const source of sources) {
        if (source?.hasOwnProperty(property)) {
            value = source[property];
            break;
        }
    }
    return value;
}
function inheritProperties(component) {
    // list of source objects in priority order
    const sources = getComponentSources(component);
    const propertiesToInherit = [];
    const meta = component[META];
    for (const property in meta.properties) {
        // build an array of properties we want to inherit
        // we can't inherit all described properties because we depend on the difference
        // between "undefined" and "not defined" to determine if a property has been overridden
        // Setting a runtime property to undefined means it has been overridden in the runtime
        // deleting a property means it has been removed from the runtime and should revert to its
        // default
        const isPropertyReactor = meta.properties?.[property]?.isReactor;
        if (isPropertyReactor) {
            propertiesToInherit.push(property);
        }
        else {
            for (const source of sources) {
                if (source?.hasOwnProperty(property)) {
                    propertiesToInherit.push(property);
                    break;
                }
            }
        }
    }
    propertiesToInherit.forEach((property) => {
        inheritProperty(component, property);
    });
    inheritProperty(component, '_meta');
}
// Merge inherited properties from a master (optional) and per-property defaults into a component
function inheritProperty(component, property) {
    const sources = getComponentSources(component);
    const meta = component[META];
    if (property === 'componentType') {
        // Don't inherit componentType!
    }
    else if (property === 'style') {
        // don't inherit style - Style reactor is init'd in View
    }
    else if (property === 'blocks') {
        // Merge blocks into _runtimeBlocks
        component._runtimeBlocks = [];
        for (const source of sources) {
            if (source?.blocks) {
                component._runtimeBlocks.push(...source.blocks);
            }
        }
        component['blocks'] = component[PRIMARY]['blocks'];
    }
    else if (property === '_meta') {
        // _meta isn't inherited but is merged elsewhere
        component['_meta'] = component[PRIMARY]['_meta'];
    }
    else {
        // Merge the sources into the component
        let value = getInheritedPropertyValue(component, property);
        // make sure the value is actually a reactor if it's supposed to be
        const isPropertyReactor = meta.properties?.[property]?.isReactor;
        if (isPropertyReactor) {
            console.assert(isReactor(value), 'Expected a reactor for property', property, value);
        }
        else if (Array.isArray(value)) {
            value = value.slice();
        }
        else if (typeof value === 'object') {
            value = { ...value };
        }
        // Clean up the reactor if we're overwriting
        if (isReactor(component[property]) && component[property] !== value) {
            component[property].dispose();
        }
        component[property] = value;
    }
}
export function getDescription(component) {
    return component[DESCRIPTION];
}
// Follow an import path through a projects import hierarchy
function resolveImport(importPath, root) {
    const path = importPath.split('/');
    for (const name of path) {
        root = root.imports?.[name];
        if (!root) {
            break;
        }
    }
    return root;
}
export function getBeforeCreatePromptProps(componentType, project) {
    const description = resolveComponentType(componentType, project);
    if (description?.['_meta']?.beforeCreatePrompt) {
        return description?.['_meta']?.beforeCreatePrompt;
    }
    return undefined;
}
// Return the ComponentMaster or ComponentDescription for a componentType
export function resolveComponentType(componentType, project) {
    let root = project;
    const { importPath, unqualifiedType } = parseComponentType(componentType);
    if (importPath) {
        // Component is referencing an imported project
        root = resolveImport(importPath, project);
        if (!root) {
            // console.warn(`Project has no ${importPath} import for "${componentType}".`);
            return undefined;
        }
    }
    if (isReactor(root)) {
        // This is a Component master
        const master = root.Components?.[unqualifiedType];
        if (!master) {
            // console.warn(`Component "${unqualifiedType}" doesn't exist. importPath:${importPath}.`);
            return undefined;
        }
        return master;
    }
    else {
        // This is a JS module, import the Description directly
        let description = root[unqualifiedType + 'Description'];
        if (!description) {
            // console.warn(`Module ${importPath} does not export ${unqualifiedType}Description`);
            return undefined;
        }
        description = buildComponentDescription(description, project);
        return description;
    }
}
// Return a ComponentDescription with protype/extends processing
function buildComponentDescription(description, project) {
    // Don't alter the original.
    description = {
        ...description,
    };
    // If the description "extends" another perform a manual inheritance.
    // Also treate componentType the same
    if (description.extends) {
        const protoDescription = resolveComponentType(description.extends, project);
        if (protoDescription && !isReactor(protoDescription)) {
            // TODO: Do we really want ALL properties inherited?
            description = { ...protoDescription, ...description };
            // Inherit the protoDescription's prototype.
            if (description.prototype &&
                description.prototype !== protoDescription?.prototype &&
                protoDescription?.prototype) {
                appendPrototype(description.prototype, protoDescription.prototype);
            }
            // Extend the metadata too
            extendDeep(description._meta, protoDescription._meta);
        }
        else {
            console.error(`Invalid Component Description for ${description.extends} while processing ${description.name}`);
        }
    }
    else {
        // Initialize the prototype
        if (description.prototype) {
            appendPrototype(description.prototype, ComponentBase);
        }
        else {
            description.prototype = ComponentBase;
        }
    }
    return description;
}
// Find, in the current project, the component at the specified path.
// Return undefined if it none exists.
// TODO: scope?
export function getComponentByPath(path) {
    if (path === undefined) {
        return undefined;
    }
    // TODO:
    return undefined;
}
export function isCustomComponentInstance(component) {
    if (!component.componentType) {
        return false;
    }
    return isReactor(component[MASTER]);
}
export function isRelativelyPositioned(component) {
    return component.positionType === 'relative';
}
export function isSection(component) {
    return !!component?.componentType && component.componentType === 'Play Kit/Section';
}
export function isProjectView(component) {
    return !!component?.componentType && component.componentType === 'Play Kit/Project';
}
export function isViewContainer(component) {
    return !!component?.componentType && !!component.children;
}
export function isFlexContainer(component) {
    // Some containers, e.g. Emitter, don't have isFlex method.
    return !!component && isContainer(component) && component.isFlexContainer?.();
}
export function isOrderedItem(component) {
    return (!!component && isSection(component)) || isFlexItem(component);
}
export function isFlexItem(component) {
    return !!component && isFlexContainer(component?.parent);
}
export function supportsFlexResizing(component) {
    return (!!component &&
        (isFlexContainer(component) || isTextComponent(component) || canFlexGrow(component)));
}
export function isTextComponent(component) {
    return [
        'Play Kit/Text' /* Legacy Text */,
        'Play Kit/Heading',
        'Play Kit/BodyText',
        'Play Kit/Subheading',
    ].includes(component.componentType);
}
export function canFlexGrow(component) {
    return !!component && !isLayoutComponent(component) && !!component[META]?.supportFlexGrow;
}
export function isFlexGrowHorizontally(component) {
    return (!!component &&
        !isSection(component) &&
        isFlexItem(component) &&
        component.horizontalResizing === 'fill');
}
export function isFlexGrowVertically(component) {
    return (!!component &&
        !isSection(component) &&
        isFlexItem(component) &&
        component.verticalResizing === 'fill');
}
export function canFlexHugHorizontally(component) {
    return !!component && isFlexContainer(component) && component.horizontalResizing === 'hug';
}
export function canFlexHugVertically(component) {
    return !!component && isFlexContainer(component) && component.verticalResizing === 'hug';
}
export function isSectionOnlyChild(component) {
    return (isSection(component) && !!component?.parent?.children && component.parent.children.length === 1);
}
export function isLayoutComponent(component) {
    return (isSection(component) ||
        isPage(component) ||
        isProjectView(component) ||
        component.name === 'rootViewContainer');
}
// return the Sections that are direct children of the root view
export function getRootViewSections(component) {
    const rootView = component.project.rootView;
    return rootView.children.filter((child) => isSection(child));
}
// If a component is relatively positioned we want to use an absolute positioned ancestor
// for certain math. This function returns the closest ancestor that is absolute positioned.
function getClosestPositionedAncestor(component) {
    let parent = component.parent;
    while (parent) {
        if (parent.positionType === 'absolute') {
            return parent;
        }
        parent = parent.parent;
    }
    return undefined;
}
// converts an array of components into an array of the closest ancestor where the position
// is known. Either a flex item or an absolute positioned component.
export function convertToOnlyPositioned(components) {
    return components
        .map((c) => {
        if (isRelativelyPositioned(c) && !isFlexItem(c)) {
            return getClosestPositionedAncestor(c);
        }
        return c;
    })
        .filter(Boolean);
}
export function eventNameFromHandlerName(handlerName) {
    return lowerFirstLetter(handlerName.slice(2));
}
function lowerFirstLetter(s) {
    return s[0].toLowerCase() + s.slice(1);
}
// A component supports design mode if its description says it does or if any of its parents do.
export function componentSupportsDesignMode(component) {
    while (component) {
        const supportComponentDesignMode = !!component[META]?.supportComponentDesignMode;
        if (supportComponentDesignMode) {
            return true;
        }
        component = component.parent;
    }
    return false;
}
export function componentSupportsCropping(component) {
    // TODO: Make this more extensible
    return component?.componentType === 'Play Kit/Image';
}
export function isEffect(component) {
    return component[DESCRIPTION]?._meta?.isEffect === true;
}
export function isAncestor(view, ancestor) {
    let c = view.parent;
    while (c) {
        if (ancestor === c) {
            return true;
        }
        c = c.parent;
    }
    return false;
}
export function getComponentPropertyDefaults(component) {
    if (component[defaults]) {
        return component[defaults];
    }
    const meta = component[META];
    const defaultProps = {};
    for (const property in meta.properties) {
        const description = meta.properties[property];
        if ('default' in description) {
            defaultProps[property] = description?.default;
        }
    }
    return defaultProps;
}
export function getDefaultPropertyValue(component, property) {
    if (component?.[defaults]?.[property]) {
        return component[defaults][property];
    }
    const description = component[META] ?? component._meta;
    return description?.properties?.[property]?.default;
}
export function hasDescribedProperty(component, property) {
    return component[META]?.properties?.[property] !== undefined;
}
